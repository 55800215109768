import React, { useEffect } from 'react';
import './newsrun.scss';
import { thongBao } from '../../img/importImg';
import withdrawAPI from '../../API/withdrawAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

function NewsRun() {
    const [settings, setSettings] = useState();

    useEffect(() => {
        try {
            const fetchGetSetting = async () => {
                const result = await withdrawAPI.getSetting();
                // console.log(result.data[0]);

                if (result.status === true) {
                    setSettings(result.data[0]);
                } else {
                    return toast.error(result.msg, { theme: 'light' });
                }
            };

            fetchGetSetting();
        } catch (error) {
            toast.error('Có lỗi xảy ra', { theme: 'light' });
        }
    }, []);

    return (
        <div className="home-news-run">
            <div className="container">
                <div className="wrap-run">
                    <h3 className="title">
                        <img
                            src={thongBao}
                            alt=""
                        />
                    </h3>

                    <div className="wrapper">
                        <p className="target">{settings && settings.notification}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsRun;
